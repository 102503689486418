<template>
    <div class="tool-sidebars">
        <h3>Tool Sidebar</h3>
        <p>👉🏼 We have a <b>Intern Sidebar</b> to projects</p>
        <br>
        <h5>Example:</h5>
        <hr>
        <div class="ba-pd-1 ba-flex ba-gp-1 ba-ai-center ba-f-column">
            <div>
                <p>Type: <b>ba-sidebar</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-sidebar'>
    <ul class='ba-nav'>
        <li class='ba-nav-item'>
            <a href='#' class='ba-link ba-is-active'>
                <builderall-icon code='8236' />
                <span class='ba-title'>Label Text</span>
            </a>
        </li>
        <li class='ba-nav-item'>
            <a href='#' class='ba-link'>
                <builderall-icon code='8236' />
                <span class='ba-title'>Label Text</span>
            </a>
        </li>
        <li class='ba-nav-item'>
            <a href='#' class='ba-link'>
                <builderall-icon code='8236' />
                <span class='ba-title'>Label Text</span>
            </a>
        </li>
        <li class='ba-nav-item'>
            <a href='#' class='ba-link'>
                <builderall-icon code='8236' />
                <span class='ba-title'>Label Text</span>
            </a>
        </li>
    </ul>
    <div class='ba-bottom'>
        <ul class='ba-bottom-list'>
            <li>
                <builderall-help />
            </li>
        </ul>
    </div>
</div>
                  "
                  height="auto"
                  width="900px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-sidebar">
                    <ul class="ba-nav">
                        <li class="ba-nav-item">
                            <a href="#" class="ba-link ba-is-active">
                                <builderall-icon code="8236" />
                                <span class="ba-title">Label Text</span>
                            </a>
                        </li>
                        <li class="ba-nav-item">
                            <a href="#" class="ba-link">
                                <builderall-icon code='8236' />
                                <span class="ba-title">Label Text</span>
                            </a>
                        </li>
                        <li class="ba-nav-item">
                            <a href="#" class="ba-link">
                                <builderall-icon code="8236" />
                                <span class="ba-title">Label Text</span>
                            </a>
                        </li>
                        <li class="ba-nav-item">
                            <a href="#" class="ba-link">
                                <builderall-icon code="8236" />
                                <span class="ba-title">Label Text</span>
                            </a>
                        </li>
                    </ul>
                    <div class="ba-bottom">
                        <ul class="ba-bottom-list">
                            <li>
                                <builderall-help />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <p>Type: <b>ba-sidebar</b></p>
                <HighCode
                  lang="html"
                  codeValue="
<div class='ba-sidebar'>
    <ul class='ba-nav'>
        <li class='ba-nav-item'>
            <input type='checkbox' checked class='ba-nav-item-input-status' id='ba-toggle-expandable-content-1'>
            <label class='ba-nav-item-expandable' for='ba-toggle-expandable-content-1'>
                <div class='ba-nav-item-expandable-content'>
                    <builderall-icon code='8236' />
                    <span class='ba-title'>Label Text</span>
                    <builderall-icon code='5da4' class='ba-link-expandable-icon' />
                </div>
                <ul class='ba-nav-subitems'>
                    <hr class='ba-hr-row'>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link ba-back'>
                            <builderall-icon code='arrow-left'></builderall-icon>
                            <span class='ba-title'>
                                Return
                            </span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link ba-current-section'>
                            <span class='ba-title'>Section 1</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link ba-is-active'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                </ul>
            </label>
        </li>
        <li class='ba-nav-item'>
            <input type='checkbox' class='ba-nav-item-input-status' id='ba-toggle-expandable-content-2'>
            <label class='ba-nav-item-expandable' for='ba-toggle-expandable-content-2'>
                <div class='ba-nav-item-expandable-content'>
                    <builderall-icon code='8236' />
                    <span class='ba-title'>Label Text</span>
                    <builderall-icon code='5da4' class='ba-link-expandable-icon' />
                </div>
                <ul class='ba-nav-subitems'>
                    <hr class='ba-hr-row'>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                </ul>
            </label>
        </li>
        <li class='ba-nav-item'>
            <input type='checkbox' class='ba-nav-item-input-status' id='ba-toggle-expandable-content-3'>
            <label class='ba-nav-item-expandable' for='ba-toggle-expandable-content-3'>
                <div class='ba-nav-item-expandable-content'>
                    <builderall-icon code='8236' />
                    <span class='ba-title'>Label Text</span>
                    <builderall-icon code='5da4' class='ba-link-expandable-icon' />
                </div>
                <ul class='ba-nav-subitems'>
                    <hr class='ba-hr-row'>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <span class='ba-title'>Label Text</span>
                        </a>
                    </li>
                    <li class='ba-nav-subitem'>
                        <a href='#' class='ba-nav-subitem-link'>
                            <builderall-icon code='others'></builderall-icon>
                            <span class='ba-title'>
                                Label Text
                            </span>
                        </a>
                    </li>
                </ul>
            </label>
        </li>
    </ul>
    </div>
                  "
                  height="auto"
                  width="900px"
                />
            </div>
            <div>
                <p>Result: </p>
                <div class="ba-sidebar">
                    <ul class="ba-nav">
                        <li class="ba-nav-item">
                            <input type="checkbox" checked class="ba-nav-item-input-status" id="ba-toggle-expandable-content-1">
                            <label class="ba-nav-item-expandable" for="ba-toggle-expandable-content-1">
                                <div class="ba-nav-item-expandable-content">
                                    <builderall-icon code="8236" />
                                    <span class="ba-title">Label Text</span>
                                    <builderall-icon code='5da4' class="ba-link-expandable-icon" />
                                </div>
                                <ul class="ba-nav-subitems">
                                    <hr class='ba-hr-row'>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link ba-back">
                                            <builderall-icon code='arrow-left'></builderall-icon>
                                            <span class="ba-title">
                                                Return
                                            </span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link ba-current-section">
                                            <span class="ba-title">Section 1</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link ba-is-active">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <builderall-icon code='settings'></builderall-icon>
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                </ul>
                            </label>
                        </li>
                        <li class="ba-nav-item">
                            <input type="checkbox" class="ba-nav-item-input-status" id="ba-toggle-expandable-content-2">
                            <label class="ba-nav-item-expandable" for="ba-toggle-expandable-content-2">
                                <div class="ba-nav-item-expandable-content">
                                    <builderall-icon code="8236" />
                                    <span class="ba-title">Label Text</span>
                                    <builderall-icon code='5da4' class="ba-link-expandable-icon" />
                                </div>
                                <ul class="ba-nav-subitems">
                                    <hr class='ba-hr-row'>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                </ul>
                            </label>
                        </li>
                        <li class="ba-nav-item">
                            <input type="checkbox" checked class="ba-nav-item-input-status" id="ba-toggle-expandable-content-3">
                            <label class="ba-nav-item-expandable" for="ba-toggle-expandable-content-3">
                                <div class="ba-nav-item-expandable-content">
                                    <builderall-icon code="8236" />
                                    <span class="ba-title">Label Text</span>
                                    <builderall-icon code='5da4' class="ba-link-expandable-icon" />
                                </div>
                                <ul class="ba-nav-subitems">
                                    <hr class='ba-hr-row'>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                    <li class="ba-nav-subitem">
                                        <a href="#" class="ba-nav-subitem-link">
                                            <span class="ba-title">Label Text</span>
                                        </a>
                                    </li>
                                </ul>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr><br>
        <h5>Helpers:</h5>
        <br>
        <table class="ba-border-table ba-mg-2">
            <thead>
                <tr>
                    <th>Option</th>
                    <th>How to do</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>closed</td>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<input type='checkbox' class='ba-nav-item-input-status' id='ba-toggle-expandable-content-#'>
                            "
                            height="auto"
                            width="900px"
                        />
                    </td>
                </tr>
                <tr>
                    <td>opened</td>
                    <td>
                        <HighCode
                            lang="html"
                            codeValue="
<input type='checkbox' checked class='ba-nav-item-input-status' id='ba-toggle-expandable-content-#'>
                            "
                            height="auto"
                            width="900px"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="scss">
    @import '../../../scss/index.scss';
</style>